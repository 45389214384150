<!--<template>
    <v-app>
        <div class="login-content color-change">
            <div class="login-container">
                <div class="login-logo animated fadeInDown slow">
                    <img src="img/logo.jpg" width="100">
                </div>		
                <div class="login-title animated fadeInDown slow">
                    <span class="login-title-text">Bienvenido {{ current_employee.fullname }}</span>
                </div>
                <div class="login-subtitle animated fadeInDown slow">			
                    <span>Selecciona tu tienda!</span>
                </div>		
                <div class="login-form">
                    <template>
                        <v-row class="animated fadeInDown slow" v-if="showCompanies">
                            <v-col cols="12">
                                <v-autocomplete
                                    v-model="value_company"
                                    :items="user_companies"
                                    label="Empresa"
                                    hide-details
                                    outlined
                                    :item-text="customText"
                                    item-value="id"
                                    @change="handleSelectCompany"
                                    :disabled="disabled_company"
                                    clearable
                                    color="black"
                                    background-color="#dddddd"

                                ></v-autocomplete>
                            </v-col>
                        </v-row>-->
                        <!--
                        <v-row class="animated fadeInDown slow">
                            <v-col cols="12">
                                <v-autocomplete
                                    v-model="value_sede"
                                    :items="sedes"
                                    hide-details
                                    label="Sede"
                                    outlined
                                    :item-text="customText"
                                    item-value="id"
                                    @change="handleSelectSede"
                                    :disabled="disabled_sede"
                                    clearable
                                    background-color="#244167"
                                    color="white"
                                    dark
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                        -->
                        <!--<v-row class="animated fadeInDown slow">
                            <v-col cols="12">
                                <v-autocomplete
                                    v-model="value_office"
                                    :items="offices"
                                    hide-details
                                    label="Sucursal"
                                    outlined
                                    :item-text="customText"
                                    item-value="id"


                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                              <v-btn
                                  color="primary"
                                  elevation="2"
                                  outlined
                                  @click="handleSelectOffice"
                                  width="100%"
                              >Ingresar</v-btn>-->

<!--                              <button @click="handleSelectOffice"  class="login-button animated fadeInUp slower btn-outline-light">Ingresar</button>-->
                            <!--</v-col>
                        </v-row>
                    </template>
                </div>
            </div>
            <v-snackbar
                v-model="show_message_login"            
                color="error"
                :timeout="3000"
                :top="'top'"
            >
                Debe seleccionar una sucursal
                <v-btn
                dark
                text
                @click="show_message_login = false"
                >
                    Cerrar
                </v-btn>
            </v-snackbar>
        </div>
    </v-app>
</template>-->

<template>
    <v-app>
      <div class="contenedor-superior">
        <div class="forma01"></div>
        <div class="forma02"></div>
        <div class="card01">
          <div
            style="
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              flex-direction: column;
              align-items: center;
            "
          >
            <div class="contenedor-logo">
              <img
                src="img/logo_pausa.png"
                style="width: auto; height: 101px;"
              />
            </div>
            <!-- <label for="" class="login-title-text">Iniciar Sesion</label> -->
            <div
              class="login-subtitle animated fadeInDown slow"
              style="padding-top: 10px"
            >
              <span>Selecciona una sucursal!</span>
            </div>
            <template>
              <div style="">
                <v-row class="animated fadeInDown slow">
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="value_office"
                      :items="offices"
                      hide-details
                      label="Sucursal"
                      outlined
                      :item-text="customText"
                      item-value="id"
                      color="rgba(100, 100, 100, 0.5)"
                      class="autoSelect"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </div>
              <div>
                <v-row>
                  <v-col cols="12">
                    <button
                      @click="handleSelectOffice"
                      class="login-button-custom animated fadeInUp slower"
                    >
                      ACCEDER
                    </button>
                  </v-col>
                </v-row>
              </div>
            </template>
          </div>
        </div>
  
        <div class="card02">
          <div style="position: relative; width: 100%; height: 100%;">
            <img src="/img/cafe.webp" alt="logo" class="background-logo" />
            <div
              style="
                  display: flex;
                  width: 100%;
                  height: 100%;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                "
            >
              <div class="login-title animated fadeInDown slow">
                <label
                  for=""
                  class="login-title-text"
                  style="color: white; font-size: 2.2rem !important"
                  >Bienvenido</label
                >
              </div>
              <div class="login-title animated fadeInDown slow">
                <span
                  class="login-title-text"
                  style="color: white; font-size: 2.2rem !important"
                >
                  {{ current_employee.fullname }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-snackbar
        v-model="show_message_login"
        color="error"
        :timeout="3000"
        :top="'top'"
      >
        Debe seleccionar una sucursal
        <v-btn dark text @click="show_message_login = false"> Cerrar </v-btn>
      </v-snackbar>
    </v-app>
  
    <!-- <v-app>
          <div class="login-content color-change">
              <div class="login-container">
                  <div class="login-logo animated fadeInDown slow">
                      <img src="img/logo-store-3.png" width="100">
                  </div>		
                  <div class="login-title animated fadeInDown slow">
                      <span class="login-title-text">Bienvenido {{ current_employee.fullname }}</span>
                  </div>
                  <div class="login-subtitle animated fadeInDown slow">			
                      <span>Selecciona tu tienda!</span>
                  </div>		
                  <div class="login-form">
                      <template>
                          <v-row class="animated fadeInDown slow" v-if="showCompanies">
                              <v-col cols="12">
                                  <v-autocomplete
                                      v-model="value_company"
                                      :items="user_companies"
                                      label="Empresa"
                                      hide-details
                                      outlined
                                      :item-text="customText"
                                      item-value="id"
                                      @change="handleSelectCompany"
                                      :disabled="disabled_company"
                                      clearable
                                      background-color="#244167"
                                      color="white"
                                      dark
                                  ></v-autocomplete>
                              </v-col>
                          </v-row>
                          <v-row class="animated fadeInDown slow">
                              <v-col cols="12">
                                  <v-autocomplete
                                      v-model="value_office"
                                      :items="offices"
                                      hide-details
                                      label="Sucursal"
                                      outlined
                                      :item-text="customText"
                                      item-value="id"
                                      background-color="#244167"
                                      color="white"
                                      dark
                                  ></v-autocomplete>
                              </v-col>
                          </v-row>
                          <v-row>
                              <v-col cols="12">
                                  <button @click="handleSelectOffice" class="login-button animated fadeInUp slower">Ingresar</button>
                              </v-col>
                          </v-row>
                      </template>
                  </div>
              </div>
              <v-snackbar
                  v-model="show_message_login"            
                  color="error"
                  :timeout="3000"
                  :top="'top'"
              >
                  Debe seleccionar una sucursal
                  <v-btn
                  dark
                  text
                  @click="show_message_login = false"
                  >
                      Cerrar
                  </v-btn>
              </v-snackbar>
          </div>
      </v-app> -->
  </template>

<script>
import {mapState, mapActions} from 'vuex';

import ArrayTools from '../../helpers/ArrayTools';

export default {
    name: 'SelectOffice',
    data() {
        return {
            showLogin: true,
            showCompanies: false,
            companies: [],
            value_company: null,
            disabled_company: false,
            sedes: [],
            value_sede: null,
            disabled_sede: false,
            offices: [],
            value_office: null,
            show_message_login: false,
        }
    },
    computed: {
        ...mapState('authentication', [
            'current_employee',
            'token',
            'select_office',
            'user_companies',
            'user_sedes',
            'user_offices'
        ])
    },
    methods: {
        ...mapActions('authentication', [
            'set_office_login'
        ]),
        ...mapActions('dashboard', [
            'getProductosVentasHoy',
            'getProductosVentasAyer',
            'getProductosGanancias',
            'getCantidadProductosMes',
            'getCantidadProductosMesPasado',
            'getTotalCantidad',
            'getReporteProductosDiarios',
            'getVentasHoy',
            'getVentasAyer',
            'getVentasMes',
            'getVentasMesPasado',
            'getTotalVentas',
            'gettotalVentasMes',
            'getRendimientoVantas',
            'getServiciosDiarios',
            'getServiciosDiariosAyer',
            'getTotalServicios',
            'getServiciosMes',
            'getServiciosMesPasado',
            'getTotalServiciosMes',
            'getCompraMes',
            'getCompraMesPasado',
            'getCompraAnio',
            'getReporteCompras'
        ]),
        async cargaGraficos(){
            await this.getProductosVentasHoy()
            await this.getProductosVentasAyer()
            await this.getProductosGanancias()
            await this.getCantidadProductosMes()
            await this.getCantidadProductosMesPasado()
            await this.getTotalCantidad()
            await this.getVentasHoy()
            await this.getVentasAyer()
            await this.getVentasMes()
            await this.getVentasMesPasado()
            await this.getTotalVentas()
            await this.gettotalVentasMes()
            await this.getRendimientoVantas()
            await this.getServiciosDiarios()
            await this.getServiciosDiariosAyer()
            await this.getTotalServicios()
            await this.getServiciosMes()
            await this.getServiciosMesPasado()
            await this.getTotalServiciosMes() 
            await this.getCompraMes()
            await this.getCompraMesPasado()
            await this.getCompraAnio()
            await this.getReporteCompras()
        },
        async handleSelectOffice() {
            if (this.value_office !== undefined && this.value_office !== null) {
                await this.set_office_login(this.value_office)
            } else {
                this.show_message_login = true;
            }
        },
        customText(item){
            let text = item.name
            
            return text
        },
        handleSelectCompany(value){
            /*
            if(value !== undefined && value !== null){
                this.sedes = ArrayTools.getSedesByCompanyId(this.user_sedes, value)
            } else {
                this.sedes = []
                this.offices = []
            }
            */
            if(value !== undefined && value !== null){
                this.offices = ArrayTools.getOfficesByCompanyId(this.user_offices, value)
            } else {
                this.offices = []
            }
        },
        handleSelectSede(value){
            if(value !== undefined && value !== null){
                this.offices = ArrayTools.getOfficesBySedeId(this.user_offices, value)
            } else {
                this.offices = []
            }
        },
        verifyCompany () {
            if (this.user_companies.length == 1) {
                this.value_company = this.user_companies[0].id;
                this.showCompanies = false;
                this.handleSelectCompany(this.value_company);
            } else {
                this.showCompanies = true;
                this.offices = []
            }
        }
    },
    async created () {
        this.verifyCompany();
        await this.cargaGraficos();
    }
}
</script>

<style scoped>
html {
  height: 100%;
  background-color: #f4f6f8 !important;
}

body {
  margin: 0px !important;
  height: 100%;
  color: #ffffff;
  font-size: 16px !important;
}

.forma01 {
  width: 13%;
  height: 25%;
  border-radius: 50%;
  position: absolute;
  left: -5%;
  top: -9%;
  /* background-image: radial-gradient(
    circle at 20.12% -14.09%,
    #de56eb 0,
    #c34cee 16.67%,
    #a343f0 33.33%,
    #783cf2 50%,
    #3039f4 66.67%,
    #0038f5 83.33%,
    #003af6 100%
  ); */
  /* background-image: radial-gradient(
    circle at -14.09% 20.12%,
    #98cd00 0,
    #7bcc00 16.67%,
    #57c900 33.33%,
    #13c600 50%,
    #00c21d 66.67%,
    #00be31 83.33%,
    #00bb42 100%
  ); */
  /*background-image: linear-gradient(
    335deg,
    #c6196b 0,
    #b0106a 16.67%,
    #970765 33.33%,
    #7c005d 50%,
    #610053 66.67%,
    #4a004a 83.33%,
    #350042 100%
  );*/
  background-image: radial-gradient(
    circle at 93.87% 80.72%, 
    #565a5e 0, 
    #545556 8.33%, 
    #52504f 16.67%, 
    #504946 25%, 
    #4e413e 33.33%, 
    #4c3936 41.67%, 
    #492f2f 50%, 
    #452529 58.33%, 
    #411c25 66.67%, 
    #3d1423 75%, 
    #380c23 83.33%, 
    #330624 91.67%, 
    #2e0325 100%
    );
}

.forma02 {
  width: 13%;
  height: 25%;
  border-radius: 50%;
  position: absolute;
  right: -6%;
  bottom: -11.5%;
  /* background-image: radial-gradient(
    circle at 5.13% 12.35%,
    #653b5e 0,
    #572c59 12.5%,
    #461d55 25%,
    #300d50 37.5%,
    #0c004c 50%,
    #000048 62.5%,
    #000046 75%,
    #000043 87.5%,
    #000041 100%
  ); */
  /* background-image: radial-gradient(
    circle at 87.65% 94.87%,
    #98cd00 0,
    #7bcc00 16.67%,
    #57c900 33.33%,
    #13c600 50%,
    #00c21d 66.67%,
    #00be31 83.33%,
    #00bb42 100%
  ); */
  /*background-image: linear-gradient(
    110deg,
    #c6196b 0,
    #b0106a 16.67%,
    #970765 33.33%,
    #7c005d 50%,
    #610053 66.67%,
    #4a004a 83.33%,
    #350042 100%
  );*/
  background-image: radial-gradient(
    circle at 93.87% 80.72%, 
    #565a5e 0, 
    #545556 8.33%, 
    #52504f 16.67%, 
    #504946 25%, 
    #4e413e 33.33%, 
    #4c3936 41.67%, 
    #492f2f 50%, 
    #452529 58.33%, 
    #411c25 66.67%, 
    #3d1423 75%, 
    #380c23 83.33%, 
    #330624 91.67%, 
    #2e0325 100%
    );
}

.contenedor-superior {
  position: relative;
  display: flex;
  widows: 100%;
  height: 100%;
  background-color: #f0f0f0;
  overflow: hidden;
}

.card01 {
  position: absolute;
  border-radius: 10px;
  margin-top: 2.5%;
  background-color: white;
  width: 35%;
  height: 75%;

  z-index: 1;

  top: 0;
  left: 0;
  right: 33%;
  bottom: 0;
  margin: auto;

  box-shadow: 0px 0px 12px 10px rgba(143, 143, 143, 0.25);
}

.card03 {
  background-color: #ff000000;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 24px;
  padding-top: 20px;
  padding-bottom: 21px;
  font-size: 12px !important;
  font-weight: bold !important;
  font-family: sans-serif !important;

  padding-left: 20px;
}

.card02 {
  position: absolute;
  border-radius: 10px;
  /* background-color: #429a22;*/ /* #0150f5; */
  width: 35%;
  height: 85%;

  top: 0;
  left: 33%;
  right: 0;
  bottom: 0;
  margin: auto;

  /* background: url("../../../public/img/7498967_33281.webp"); */

  box-shadow: 0px 0px 12px 10px rgba(143, 143, 143, 0.25);
}

.background-logo {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  z-index: 0;
}

.path-img {
  background-image: radial-gradient(
    circle at 20.12% -14.09%,
    #de56eb 0,
    #c34cee 16.67%,
    #a343f0 33.33%,
    #783cf2 50%,
    #3039f4 66.67%,
    #0038f5 83.33%,
    #003af6 100%
  );
}

.login-button-custom {
  width: 100%;

  height: 45px;
  border-radius: 5px;
  border: 1px solid #a3225e;/*#493030;*//*#9f1f63;*/
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 12px;
  padding-right: 12px;
  display: block;
  margin-top: 20px;
  margin-bottom: 4px;
  color: #ffffff;
  background-color: #a3225e;/*#493030;*//*#9f1f63;*/ /* #26beba 26beba   03164e*/
  cursor: pointer;
  font-family: sans-serif !important;
  font-size: 1rem;
  font-weight: bold;
  outline: none;
}
.login-content {
  background-color: #03164e !important;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #ffffff;
  font-family: "Baloo Bhaina 2" !important;
  font-size: 16px !important;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #ffffff;
  font-family: "Baloo Bhaina 2" !important;
}

.login-logo {
  margin-bottom: 15px;
}

.login-title {
  display: block;
  margin-bottom: 15px;
}

.login-subtitle {
  margin-bottom: 15px;
}

.login-form {
  display: block !important;
  height: 250px !important;
}

.login-textfield {
  height: 40px !important;
  border-radius: 14px !important;
  border: 1px solid #244167 !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  display: block !important;
  margin-top: 20px !important;
  margin-bottom: 4px !important;
  width: 270px !important;
  color: #ffffff !important;
  background-color: #244167 !important;
  outline: none !important;
  font-family: "Baloo Bhaina 2" !important;
  font-size: 0.9rem !important;
}

.login-button {
  height: 45px;
  border-radius: 14px;
  border: 1px solid #26beba;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 12px;
  padding-right: 12px;
  display: block;
  margin-top: 10px;
  margin-bottom: 4px;
  width: 100%;
  color: #03164e;
  background-color: #26beba;
  cursor: pointer;
  font-family: "Baloo Bhaina 2";
  font-size: 1rem;
  font-weight: bold;
  outline: none;
}

.login-title-text {
  font-size: 2.3rem !important;
  font-weight: 500 !important;
}

::-webkit-input-placeholder {
  /* Edge */
  color: #ffffff !important;
  font-family: "Baloo Bhaina 2" !important;
  font-size: 0.9rem !important;
}

:-ms-input-placeholder {
  /* Internet Explorer */
  color: #ffffff !important;
  font-family: "Baloo Bhaina 2" !important;
  font-size: 0.9rem !important;
}

::placeholder {
  color: #ffffff !important;
  font-family: "Baloo Bhaina 2" !important;
  font-size: 0.9rem !important;
}

.v-input__slot {
  background-color: #244167 !important;
}
</style>

<!--<style scoped>
html{
	height: 100%;
	background-color: #f4f6f8 !important;
}

body{
	margin: 0px !important;
	height: 100%;	
	color: rgba(23, 22, 22, 0.89);
    font-size: 16px !important;
}

.login-content{
	background-color: #ffffff !important;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
    flex-direction: column;
    color: rgba(19, 19, 19, 0.8);
    font-family: 'Baloo Bhaina 2' !important;
    font-size: 16px !important;
}

.login-container{
	display: flex;
	justify-content: center;
	align-items: center;
    flex-direction: column;
  color: rgba(19, 19, 19, 0.8);
    font-family: 'Baloo Bhaina 2' !important;
}

.login-logo{
    margin-bottom: 15px;
}

.login-title{
    display: block;
    margin-bottom: 15px;
}

.login-subtitle{
    margin-bottom: 15px;
}

.login-form{
	display: block !important;
	height: 250px !important;
}

.login-textfield{
    height: 40px !important;
    border-radius: 14px !important;
    border: 1px solid #244167 !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    display: block !important;
    margin-top: 20px !important;
    margin-bottom: 4px !important;
    width: 270px !important;
    color: #ffffff !important;
    background-color: #244167 !important;
    outline: none !important;
    font-family: 'Baloo Bhaina 2' !important;
	font-size: .9rem !important;
}

.login-button{
	height: 45px;
    border-radius: 14px;
    border: 1px solid #26beba;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 12px;
    padding-right: 12px;
    display: block;
    margin-top: 10px;
    margin-bottom: 4px;
    width: 100%;
    color: #03164e;
    background-color: #26beba;
    cursor: pointer;
    font-family: 'Baloo Bhaina 2';
    font-size: 1rem;
    font-weight: bold;
    outline: none;
}

.login-title-text{
	font-size: 2.3rem !important;
    font-weight: 500 !important;
}

::-webkit-input-placeholder { /* Edge */
	color: rgba(19, 19, 19, 0.8) !important;
	font-family: 'Baloo Bhaina 2' !important;
	font-size: .9rem !important;
}

:-ms-input-placeholder { /* Internet Explorer */
	color: rgba(19, 19, 19, 0.8) !important;
	font-family: 'Baloo Bhaina 2' !important;
	font-size: .9rem !important;
}

::placeholder {
	color: rgba(19, 19, 19, 0.8) !important;
	font-family: 'Baloo Bhaina 2' !important;
	font-size: .9rem !important;
}

.v-input__slot {
    background-color: #656767 !important;
}

</style>-->